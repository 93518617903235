<template>
  <payment-method-form
    ref="payment-methods-form"
    :is-create-form="true"
  />
</template>

<script>

import config from '../config'
import PaymentMethodForm from '../components/Form.vue'

export default {
  name: 'Create',
  inject: ['paymentMethodsModuleName'],
  components: {
    PaymentMethodForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['payment-methods-form']) {
      this.$refs['payment-methods-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.$store.commit(`${this.paymentMethodsModuleName}/SET_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_METHODS_FORM_CLONE`, {})
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
